import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { isMobile } from "react-device-detect";
import BeerInput from "./components/LandingPage";
import BeerInfo from "./components/BeerInfo";
import NotMobile from "./components/NotMobile";
import Header from "./components/Header";

const App = () => {
  if (!isMobile) {
    return <NotMobile />;
  }

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<BeerInput />} />
        <Route path="/info" element={<BeerInfo />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
