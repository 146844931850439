import React, { useState } from "react";
import { Link } from "react-router-dom";
import BeerSlot from "./BeerSlot";

const LandingPage = () => {
  const [beers, setBeers] = useState(
    () => JSON.parse(sessionStorage.getItem("beers")) || Array(5).fill("")
  );

  const handleBeerChange = (index, selectedBeer) => {
    const newBeers = [...beers];
    newBeers[index] = selectedBeer;
    setBeers(newBeers);
    sessionStorage.setItem("beers", JSON.stringify(newBeers));
  };

  const handleClick = () => {
    sessionStorage.removeItem("beers");
    setBeers(Array(5).fill(""));
  };

  return (
    <div className="landing-page">
      <h1>Create Your Beer Flight!</h1>
      <p>Click on each number and add the matching beer. Happy Drinking!</p>
      <img
        src={`${process.env.PUBLIC_URL}/beerflight.png`}
        alt="A beerflight"
        className="beerflight"
      />
      {beers.map((beer, index) => (
        <BeerSlot
          key={index}
          index={index}
          beer={beer}
          onChange={(selectedBeer) => handleBeerChange(index, selectedBeer)}
        />
      ))}
      <button className="reset-btn" onClick={handleClick}>
        Reset Flight
      </button>
      <Link to="/info">View Beer Info</Link>
    </div>
  );
};

export default LandingPage;
