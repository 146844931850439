import React from 'react';

const NotMobile = () => {
  return (
    <div>
      <h1>This app is only available on mobile devices</h1>
    </div>
  );
};

export default NotMobile;
