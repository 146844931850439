import React from "react";
import { dummyBeerData } from "../dummyBeerData";

const BeerList = ({ onSelect, onClose }) => {
  const handleSelect = (beerName) => {
    onSelect(beerName);
    onClose();
  };

  return (
    <div className="beerlist-overlay" onClick={onClose}>
      <div
        className="beerlist-container"
        onClick={(e) => e.stopPropagation()} // Prevent click propagation
      >
        <button onClick={onClose} className="beerlist-closeButton">
          Close
        </button>
        {dummyBeerData.map((beer) => (
          <div
            key={beer.name}
            onClick={() => handleSelect(beer.name)}
            className="beerlist-beerItem"
          >
            <img src={beer.image} alt={beer.name} width="50" />
            <div className="beer-info-div">
              <span>{beer.name}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BeerList;
