import React, { useState, useEffect } from "react";
import { dummyBeerData } from "../dummyBeerData";

const BeerInfo = () => {
  const [beerInfo, setBeerInfo] = useState([]);

  useEffect(() => {
    setBeerInfo(dummyBeerData);
  }, []);

  return (
    <div className="beer-info-page">
      {beerInfo.map((beer, index) => (
        <div key={index} className="beer-info">
          <div className="beer-info-top">
            <h2>{beer.name}</h2>
            <p>{beer.abv}</p>
          </div>

          <div className="beer-info-middle">
            <img src={beer.image} alt={beer.name} />
            <div className="beer-info-middle-text">
              <p>
                <strong>Ingredients:</strong> {beer.ingredients.join(", ")}
              </p>
              <p>
                <strong>Description:</strong> {beer.description}
              </p>
            </div>
          </div>
          <div className="beer-info-bottom">
            <p>
              <strong>Fun Fact:</strong> {beer.funFact}
            </p>
          </div>
        </div>
      ))}
      <a href="/">Go Back</a>
    </div>
  );
};

export default BeerInfo;
