import React, { useState, useEffect } from "react";
import BeerList from "./BeerList";
import { dummyBeerData } from "../dummyBeerData";

const BeerSlot = ({ beer, onChange, index }) => {
  const [showBeerList, setShowBeerList] = useState(false);

  const handleBeerClick = (selectedBeer) => {
    onChange(selectedBeer);
    setShowBeerList(false);
  };

  useEffect(() => {
    if (showBeerList) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [showBeerList]);

  return (
    <div className="beerslot-container" onClick={() => setShowBeerList(true)}>
      {beer ? (
        <div className="beerslot-data">
          <img
            src={dummyBeerData.find((b) => b.name === beer)?.image}
            alt={beer}
            width="50"
          />
          <span>{beer}</span>
          <span className="info-number">{index + 1}</span>
        </div>
      ) : (
        <span>{index + 1}</span>
      )}

      {showBeerList && (
        <BeerList
          onSelect={handleBeerClick}
          onClose={() => setShowBeerList(false)}
        />
      )}
    </div>
  );
};

export default BeerSlot;
