import React, { useState, useEffect } from "react";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`header ${scrolled ? "scrolled" : ""}`}>
      <div className="logo">
        <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" />
      </div>
      <nav className="nav">
        <ul>
          <li>
            <a
              href="https://barlindbeer.se/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit Barlindbeer.se
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
