export const dummyBeerData = [
  {
    name: "Fyren och Båken",
    abv: "2.1%",
    ingredients: [
      "Water",
      "Barley malt",
      "Centennial, Citra, Mosaic hops",
      "Yeast",
    ],
    image: "/images/Fyren och Båken.png",
    description: "A fresh and flavorful light ale with a pleasant bitterness.",
    funFact:
      "Fyren och Båken pays homage to Vinga and its landmarks, and is the only regular light ale brewed in the Gothenburg area.",
  },
  {
    name: "BjörköAle",
    abv: "2.7%",
    ingredients: ["Water", "Barley malt", "Amarillo, Mosaic hops", "Yeast"],
    image: "/images/Björkö Ale.png",
    description:
      "A low-alcohol beer that delivers rich flavors with a pronounced bitterness.",
    funFact:
      "BjörköAle won a bronze medal at a Beer & Whisky Fair in Gothenburg in 2016.",
  },
  {
    name: "Pilsner 475",
    abv: "3.5%",
    ingredients: ["Water", "Barley malt", "Wheat malt", "Hops", "Yeast"],
    image: "/images/Pilsner 475.png",
    description:
      "A light lager with robust flavors, named after Öckerö municipality's postal code prefix.",
    funFact: "Pilsner 475 received top marks in Göteborgs Posten in June 2021.",
  },
  {
    name: "IPA swim with Mango",
    abv: "3.5%",
    ingredients: ["Water", "Barley malt", "Amarillo, Mosaic hops", "Yeast"],
    image: "/images/IPA swim with Mango.png",
    description:
      "A fruity IPA with enhanced hop flavors from mango and a distinct bitterness.",
    funFact:
      "The name for IPA swim with Mango was inspired by neighbors Anna & Johnny when the brewer had a creative block.",
  },
  {
    name: "Flygtornets pilsner",
    abv: "4.6%",
    ingredients: ["Water", "Barley malt", "Hops", "Yeast"],
    image: "/images/Flygtornets pilsner.png",
    description:
      "A light, crisp pilsner named after Göteborg's former airport's space-inspired tower.",
    funFact:
      "Torslanda Airport was replaced by Landvetter in 1977, and its tower inspired the name for Flygtornets pilsner.",
  },
  {
    name: "Ishusets lager",
    abv: "4.9%",
    ingredients: [
      "Water",
      "Barley malt",
      "Wheat malt",
      "Perle, Saaz hops",
      "Yeast",
    ],
    image: "/images/Ishusets lager.png",
    description: "A light lager named after the Ishus in Björkö harbor.",
    funFact:
      "In autumn 2019, BarlindBeer relocated its operations to the Ishus.",
  },
  {
    name: "Flygtornets schwarzbier",
    abv: "5.0%",
    ingredients: [
      "Water",
      "Barley malt",
      "Herzbrucker, Perle hops",
      "Yeast",
      "Smoked sugar kelp",
    ],
    image: "/images/Flygtornets schwarzbier.png",
    description:
      "A dark lager with subtle roasted tones and a hint of smoked sugar kelp.",
    funFact:
      "This beer uses cultivated, smoked sugar kelp from the sea near Björkö.",
  },
  {
    name: "Archipelago APA",
    abv: "5.6%",
    ingredients: ["Water", "Barley malt", "Citra hops", "Yeast"],
    image: "/images/Archipelago APA.png",
    description: "A fruity and refreshing pale ale with a balanced bitterness.",
    funFact: "Archipelago APA was created for Kalvsundsdagarna in summer 2015.",
  },
  {
    name: "Tôga",
    abv: "6.2%",
    ingredients: ["Water", "Barley malt", "Wheat, oats", "Hops", "Yeast"],
    image: "/images/Tôga.png",
    description: "A hazy IPA with tropical fruit notes and low bitterness.",
    funFact:
      "Tôga means 'fog' in the local dialect, named after Ishuset in Björkö.",
  },
  {
    name: "Ten Island IPA",
    abv: "6.5%",
    ingredients: [
      "Water",
      "Barley malt",
      "Oatmeal",
      "Amarillo, Citra, Mosaic hops",
      "Yeast",
    ],
    image: "/images/Ten Island IPA.png",
    description:
      "A well-balanced IPA with a distinct bitterness and flavors of malt and hops.",
    funFact:
      "Ten Island IPA was the winning brew of BarlindBeer's homebrew competition.",
  },
  {
    name: "Smegevôrna",
    abv: "7.0%",
    ingredients: ["Water", "Barley malt", "Wheat, oats", "Hops", "Yeast"],
    image: "/images/Smegevôrna.png",
    description:
      "A creamy and fruity IPA with low bitterness and tropical fruit notes.",
    funFact: "Smegevôrna means 'beautiful weather' in the local dialect.",
  },
  {
    name: "Barcode",
    abv: "8.3%",
    ingredients: ["Water", "Barley malt", "Oatmeal", "Hops", "Yeast"],
    image: "/images/Barcode.png",
    description:
      "A powerful stout with flavors of coffee, chocolate, and licorice.",
    funFact:
      "The label design of Barcode is a stylized barcode, making it possibly the world's most scannable bottle.",
  },
  {
    name: "Påskefyr 2024",
    abv: "6.0%",
    ingredients: [
      "Water",
      "Barley malt",
      "Wheat malt",
      "Oats",
      "Hops",
      "Yeast",
    ],
    image: "/images/Påskefyr 2024.png",
    description:
      "A cloudy and fruity IPA with low bitterness and a vibrant Easter yellow color.",
    funFact:
      "Påskefyr 2024 celebrates the tradition of lighting Easter bonfires on the highest points of Öckerö islands.",
  },
  {
    name: "Narrefyr 2023",
    abv: "3.5%",
    ingredients: [
      "Water",
      "Barley malt",
      "Wheat malt",
      "Oats",
      "Lactose",
      "Hops",
      "Yeast",
    ],
    image: "/images/Narrefyr 2023.png",
    description:
      "A variant of Påskefyr with tropical fruit flavors and low bitterness, brewed with lactose for added richness.",
    funFact:
      "Narrefyr is a yearly variant of Påskefyr, released approximately a month before.",
  },
  {
    name: "God Ale",
    abv: "6.4%",
    ingredients: ["Water", "Barley malt", "Wheat malt", "Hops", "Yeast"],
    image: "/images/God Ale.png",
    description:
      "A rich and flavorful ale with notes of dark syrup, caramel, chocolate, coffee, and orange peel.",
    funFact: "God Ale is a traditional Christmas ale with a festive design.",
  },
  {
    name: "Folklig God Ale",
    abv: "3.0%",
    ingredients: ["Water", "Barley malt", "Hops", "Yeast"],
    image: "/images/Folklig God Ale.png",
    description:
      "A malty and flavorful ale, similar in taste to God Ale but with a lower alcohol content.",
    funFact: "",
  },
];
